import leaderBoard from '@/redux/slices/leaderBoard'
import NextImage from '@/utils/Image'
import LeaderBoardTable from './AgGridLeaderboard'

const RankRenderer = (props) => {
  const { rowIndex } = props
  let path = ''
  if (images[rowIndex]) {
    path = images[rowIndex]
  } else {
    return +rowIndex + 1
  }
  return (
    path !== '' && (
      <NextImage width={65} height={65} className="h-[65px]" src={path} />
    )
  )
}
const WinRenderer = (props) => {
  const data = props.data
  return <div className="text-sm font-medium text-[#12B76A]">{data.won}</div>
}
const changeHandlerLeader = (e) => {
  if (gridRef && gridRef.current) {
    gridRef.current.api.setQuickFilter(e.target.value)
  }
}
const gridStyle = {
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
}
const images = {
  0: '/img/badgeYellow.webp',
  1: '/img/badgePink.webp',
  2: '/img/badgeBlue.webp',
  3: '/img/badgeGray.webp',
}
const LeaderNameRenderer = (props) => {
  const data = props.data
  return (
    <div className="text-sm flex gap-x-2 font-medium w-[120px] capitalize">
      {data?.hideLeaderboard ? (
        <>
          <NextImage
            width={20}
            height={20}
            className="md:w-[40px] md:h-[40px] h-[30px] w-[30px] rounded-[50%] self-center mr-3 bg-[#98A2B3] p-2"
            src="/svgs/anonymous.svg"
          />
          <p className="self-center">Hidden</p>
        </>
      ) : (
        <>
          <NextImage
            width={20}
            height={20}
            className="md:w-[40px] md:h-[40px] h-[30px] w-[30px] rounded-[50%] self-center mr-3"
            src={
              data?.profileUrl
                ? data?.profileUrl
                : '/img/blank-profil-picture.webp'
            }
          />
          <p className="self-center">
            {data.player_name ? data.player_name : 'Hidden'}
          </p>
        </>
      )}
    </div>
  )
}
const LostRenderer = (props) => {
  const data = props.data
  return <div className="text-sm font-medium">{data.lose}</div>
}
const ResultRenderer = (props) => {
  const data = props.data
  return (
    <>
      {/* <p className="mt-[2px] text-sm font-medium mr-4">
        ${Number(data.totalWonAmount).toFixed(2)}
      </p> */}
      <div className="bg-[#12b76a1a] text-[#12B76A] w-[55px] h-[20px] text-xs text-center rounded-[20px] self-center ">
        <p className="mt-[2px]">
          {Number(data.aggregateReturnPercent).toFixed(2)}%
        </p>
      </div>
    </>
  )
}
const BestBetRenderer = (props) => {
  const data = props.data
  return (
    <div className="text-sm font-medium">
      $
      {data.bestBet
        ? Number(data.bestBet) > 1
          ? Number(data.bestBet).toFixed(2)
          : Number(data.bestBet).toFixed(6)
        : '0.00'}
    </div>
  )
}

const columnDefs = [
  {
    headerName: `Rank`,
    defaultHeaderName: 'Rank',
    field: 'Rank',
    suppressMovable: true,
    cellRenderer: RankRenderer,
    minWidth: 150,
    maxWidth: 300,
  },
  {
    headerName: 'Leaders Name',
    field: 'player_name',
    suppressMovable: true,
    cellRenderer: LeaderNameRenderer,
    minWidth: 250,
    maxWidth: 300,
  },
  {
    headerName: 'Best Bet',
    field: 'bestBet',
    suppressMovable: true,
    cellRenderer: BestBetRenderer,
    minWidth: 100,
    maxWidth: 200,
  },
  {
    headerName: 'Won',
    field: 'won',
    suppressMovable: true,
    cellRenderer: WinRenderer,
    minWidth: 150,
    maxWidth: 300,
  },
  {
    headerName: 'Lost',
    field: 'lose',
    suppressMovable: true,
    cellRenderer: LostRenderer,
    minWidth: 130,
    maxWidth: 260,
  },
  {
    headerName: 'ROI',
    field: 'aggregateReturnPercent',
    suppressMovable: true,
    cellRenderer: ResultRenderer,
    minWidth: 150,
    maxWidth: 300,
    sort: 'desc',
  },
]
const defaultColDef = {
  sortable: false,
}
export { defaultColDef, columnDefs, gridStyle }
