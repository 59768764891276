import { americanToDecimal } from 'bookmakerjs'

export const calculateCoefficient = (oddData) => {
  if (oddData?.betSlips) {
    return Number(
      oddData?.betSlips?.reduce((acc, curr) => {
        const { price } = curr
        const decimalPrice = americanToDecimal(price)
        return acc * decimalPrice
      }, 1) || 0.0,
    )?.toFixed(2)
  } else {
    return americanToDecimal(oddData?.price)
  }
}

export const statusColors = {
  active: 'text-[#F7941D]',
  push: 'text-[#F7941D]',
  win: 'text-[#12B76A]',
  lose: 'text-[#F04438]',
  grey: 'bg-white/10 text-white',
  yellow: 'bg-[#F79009]/20 text-[#F79009]',
  red: 'bg-[#F04438]/10 text-[#F04438]',
  blue: 'bg-[#0096FF]/10 text-[#0096FF]',
  green: 'bg-[#12B76A]/10 text-[#12B76A]',
  completed: 'text-[#12B76A]',
  na: 'text-[#F04438]',
}

export const dateComparator = (date1, date2) => {
  const dateData1 = new Date(date1)
  const newdate1 = Math.floor(dateData1.getTime() / 1000)

  const dateData2 = new Date(date2)
  const newdate2 = Math.floor(dateData2.getTime() / 1000)

  const date1Number = newdate1
  const date2Number = newdate2
  if (date1Number === null && date2Number === null) {
    return 0
  }
  if (date1Number === null) {
    return -1
  }
  if (date2Number === null) {
    return 1
  }
  return date1Number - date2Number
}

export const numberSort = (num1, num2) => {
  return num1 - num2
}
