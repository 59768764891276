import React, { useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { defaultColDef, columnDefs, gridStyle } from './helper'

import { FormattedMessage, useIntl } from 'react-intl'

export let gridRef = ''
const LeaderBoardTable = ({ leaderInfo, userDetails, isHome = false }) => {
  gridRef = useRef(null)
  const intl = useIntl()

  return (
    <div
      className={`h-[500px] pb-[0.5] mx-auto my-8 ${
        isHome === true ? 'w-[100%]' : 'w-[95%] md:w-[97%] lg:max-w-7xl'
      } `}
      id="grid-wrapper"
    >
      <div style={gridStyle} className="ag-theme-alpine-dark">
        <AgGridReact
          gridOptions={{
            getRowHeight: () => {
              return 70
            },
          }}
          ref={gridRef}
          rowData={leaderInfo.length !== 0 ? leaderInfo : userDetails}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          // getRowHeight={getRowHeight}
          groupDefaultExpanded={1}
          animateRows={true}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; margin-top:10vh">New week has begun! Waiting for Game Results. Check back later to see where you rank!</span>'
          }
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit()
          }}
        />
      </div>
      {/* <FormattedMessage id='eaderBoard.footer.message' defaultMessage="New week has begun! Waiting for Game Results. Check back later to see where you rank!" /> */}
    </div>
  )
}

export default LeaderBoardTable
