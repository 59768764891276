import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import formatDate from '@/utils/formatDate'
import { calculateCoefficient, statusColors, numberSort } from './data'
import { StatusChip } from './StatusChip'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import NextImage from '@/utils/Image'
import {
  getSportByName,
  getTeamAbbreviation,
} from '@/constants/countries-leagues'
import { useMediaQuery } from 'react-responsive'
import authSelector from '@/redux/selectors/auth'
import { FormattedMessage } from 'react-intl'

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule])

const getSinglePossibleWin = ({ betPrice = 0, price = 0 }) => {
  let possibleWin = 0

  if (price > 0) {
    possibleWin = betPrice * (price / 100)
  } else {
    possibleWin = betPrice * (100 / Math.abs(price))
  }

  return isNaN(possibleWin) ? 0 : possibleWin
}

const BetIdRenderer = (props) => {
  const data = props.data

  return (
    <div className="text-sm font-medium  cursor-pointer" title={data.betID}>
      {data.betID}
    </div>
  )
}

const SportRenderer = (props) => {
  const data = props.data

  let sport = ''
  if (data.betSlips) {
    return (
      <div className="text-sm font-medium capitalize flex gap-x-2 w-[100%]">
        <div className="bg-[#15191B] rounded-lg p-2.5">
          <NextImage
            src="/svgs/layers-three.svg"
            width={20}
            height={20}
            alt="layers three"
          />
        </div>
        <div className="p-2">
          <span className="capitalize ">{`${data.betType} (${data.betSlips.length})`}</span>
        </div>
      </div>
    )
  } else {
    sport = data?.match?.sport
  }

  const currentSport = getSportByName(sport)
  return (
    <div className="text-sm font-medium capitalize flex gap-x-2 w-[100%]">
      <div className={`bg-[#15191B] rounded-lg p-2.5 relative `}>
        <NextImage
          src={currentSport.logo}
          width={20}
          height={20}
          alt={currentSport.name}
        />
      </div>
      <div className="p-2 ">
        <span>{sport}</span>
      </div>
    </div>
  )
}

const SportMobileRenderer = (props) => {
  const data = props.data

  if (data.betSlips) {
    return (
      <div className="text-sm font-medium capitalize flex gap-x-2 w-[100%]">
        <div className="bg-[#15191B] rounded-lg p-2">
          <NextImage
            src="/svgs/layers-three.svg"
            width={20}
            height={20}
            alt="layers three"
          />
        </div>
        <div className="p-2">
          <span className="capitalize ">{`${data.betType} (${data.betSlips.length})`}</span>
        </div>
      </div>
    )
  }

  const currentSport = getSportByName(data?.match?.sport)

  return (
    <div className="text-sm font-medium capitalize flex gap-x-2 w-[100%] ">
      <div className={`bg-[#15191B] rounded-lg p-2 relative`}>
        <NextImage
          src={currentSport.logo}
          width={20}
          height={20}
          alt={currentSport.name}
        />
      </div>
      <div className="text-sm font-medium my-auto text-center">
        {data?.future ? (
          <div>{data.future.slice(0, 4) + '...'}</div>
        ) : (
          <div>
            {getTeamAbbreviation(data?.match?.league, data?.match?.home_team)}{' '}
            <> - </>
            {getTeamAbbreviation(data?.match?.league, data?.match?.away_team)}
          </div>
        )}
      </div>
    </div>
  )
}

const ResultRenderer = (props) => {
  const data = props.data

  if (data.status === '') {
    return <></>
  }

  const status =
    data?.status === 'active'
      ? 'active'
      : data?.grade === 'win'
      ? 'win'
      : data?.grade === 'loss'
      ? 'lose'
      : data?.grade === 'refund'
      ? 'push'
      : ''
  return (
    <StatusChip
      status={status}
      className={`${statusColors[status]} capitalize`}
    />
  )
}
const PaymentStatusRenderer = (props) => {
  const data = props.data
  if (data.status === '') {
    return <></>
  }
  const status =
    data?.status === 'active'
      ? 'active'
      : data?.status === 'completed'
      ? 'completed'
      : 'NA'

  return (
    <StatusChip
      status={status}
      className={`${statusColors[status]} capitalize`}
    />
  )
}

const BetRenderer = (props) => {
  const { usdToBsv, data } = props
  let bet = ''
  let bsv = ''
  try {
    if (data.betSlips) {
      bet = data.price
      if (usdToBsv) {
        bsv = usdToBsv(bet)
        if (bsv) {
          bsv = bsv.toFixed(4)
        } else {
          bsv = ''
        }
      }
    } else {
      bet = data.betPrice
      if (usdToBsv) {
        bsv = usdToBsv(bet).toFixed(4)
      }
    }
  } catch (e) {}

  if (bet.length === 0) {
    return <></>
  }

  return (
    <div className="text-sm font-medium space-y-2 ">
      <div className="flex gap-x-2 items-center relative">
        <NextImage width={16} height={16} src="/svgs/BSV.svg" alt="bsv" /> {bsv}
      </div>
      <div>$ {bet}</div>
    </div>
  )
}

const MatchRenderer = (props) => {
  const data = props.data

  if (data.betSlips) {
    return <></>
  }

  return (
    <div className="text-sm font-medium space-y-2 text-center">
      {data?.future ? (
        <div className="whitespace-pre-wrap">{data.future}</div>
      ) : (
        <>
          <div>{data?.match?.home_team}</div>
          <div>{data?.match?.away_team}</div>
        </>
      )}
    </div>
  )
}

const DateRenderer = (props) => {
  const data = props.data
  if (data.betSlips) {
    return <></>
  }
  return (
    <div className="text-sm font-medium space-y-2 ">
      <div>{formatDate(data?.placedAt, 'MMM DD, YYYY')}</div>
      <div>{formatDate(data?.placedAt, 'hh:mm:ss a')}</div>
    </div>
  )
}

const WinRenderer = (props) => {
  const { usdToBsv, data } = props

  if (data?.price === '' || data?.refunded) {
    return <></>
  }

  if (data?.betSlips) {
    return (
      <div className="text-sm font-medium space-y-2 ">
        {data?.status !== 'active' && (
          <div className="flex gap-x-2 items-center relative">
            <NextImage width={16} height={16} src="/svgs/BSV.svg" alt="bsv" />
            {data?.grade === 'win'
              ? `${usdToBsv(parseFloat(data.possibleWinPrice))?.toFixed(8)}`
              : `-${usdToBsv(parseFloat(data?.price))?.toFixed(8)}`}
          </div>
        )}
        <div>
          {data?.status !== 'active' && '$ '}
          {data?.status !== 'active' &&
            (data?.grade === 'win'
              ? `${parseFloat(data.possibleWinPrice)?.toFixed(8)}`
              : `-${parseFloat(data?.price)?.toFixed(8)}`)}
        </div>
      </div>
    )
  }

  return (
    <div className="text-sm font-medium space-y-2 ">
      {data?.status !== 'active' && (
        <div className="flex gap-x-2 items-center relative">
          <NextImage width={16} height={16} src="/svgs/BSV.svg" alt="bsv" />
          {data?.grade === 'win'
            ? `${usdToBsv(
                getSinglePossibleWin({
                  betPrice: data?.betPrice,
                  price: data?.price,
                }),
              )?.toFixed(8)}`
            : `-${usdToBsv(
                isNaN(data?.betPrice) ? 0.0 : Number(data?.betPrice),
              )?.toFixed(8)}`}
        </div>
      )}
      <div>
        {data?.status !== 'active' && '$ '}
        {data?.status !== 'active' &&
          (data?.grade === 'win'
            ? `${getSinglePossibleWin({
                betPrice: data?.betPrice,
                price: data?.price,
              })?.toFixed(8)}`
            : `-${(isNaN(data?.betPrice)
                ? 0.0
                : Number(data?.betPrice)
              )?.toFixed(8)}`)}
      </div>
    </div>
  )
}

const CoefRenderer = (props) => {
  const data = props.data

  if (data.price === '') {
    return <></>
  }

  return (
    <StatusChip
      status={calculateCoefficient(data)}
      className={`${statusColors['grey']} capitalize`}
    />
  )
}

const DropDownRenderer = (props) => {
  const { data } = props

  if (!data.betSlips) {
    return <></>
  }

  return (
    <div className="px-3 py-4 text-sm cursor-pointer relative" align="center">
      {data.collapsed === false ? (
        <NextImage fill src="/svgs/arrow-up-white.svg" alt="arrow-up" />
      ) : (
        <NextImage fill src="/svgs/arrow-down-white.svg" alt="arrow-down" />
      )}
    </div>
  )
}

const BetsTable = ({
  usdToBsv,
  onIdPress,
  isHome = false,
  betSnapShotRef,
  isMyBets,
  allBets: placedBets,
}) => {
  const gridStyle = { height: '100%', width: '100%', boxSizing: 'border-box' }
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const { user, isAuthenticated } = useSelector(authSelector)
  const router = useRouter()
  const isAdmin = user?.roleType === 'admin'
  const isAdminBets =
    router.pathname.includes('bets') && isAdmin && isAuthenticated === true
  const [detected, setDetected] = useState(false)
  const [collapse, setCollapse] = useState(false)

  const gridRef = useRef(null)

  const onCellClicked = ({ data }) => {
    onIdPress(data)
  }
  const [allBets, setAllBets] = useState([])
  useEffect(() => {
    if (gridRef.current && gridRef.current.api && collapse === false) {
      const newBets = placedBets.map((all) => {
        const obj = { ...all }
        obj['rowHeight'] = 65
        if (all.betSlips) {
          obj['collapsed'] = true
        }
        return obj
      })

      const prevBets = []
      gridRef.current.api.forEachNode(function (node) {
        prevBets.push(node.data)
      })
      const uniques = newBets
        .concat(prevBets)
        .filter(
          (item) =>
            !newBets.some((i) => i.betID === item.betID) ||
            !prevBets.some((i) => i.betID === item.betID),
        )

      uniques.sort((a, b) => {
        return a.checked_date - b.checked_date
      })
      gridRef.current.api.applyTransaction({
        add: uniques,
        addIndex: gridRef.current.api.getLastDisplayedRow() + 1,
      })
    }
  }, [placedBets])

  useEffect(() => {
    const mapped = placedBets.map((all) => {
      const obj = { ...all }
      obj['rowHeight'] = 65
      if (all.betSlips) {
        obj['collapsed'] = true
      }
      return obj
    })
    setAllBets(mapped)
  }, [placedBets])

  const sortModel = [
    { colId: 'placedAt', sort: 'asc' }, // Specify the column ID and sort order
  ]

  const columnDefs = [
    {
      headerName: 'Bet ID',
      field: 'betID',
      suppressMovable: true,
      cellRenderer: BetIdRenderer,
      sortable: false,
      unSortIcon: true,
      minWidth: 100,
      valueGetter: (params) => {
        const { data } = params
        if (data.betID) {
          return data.betID.toLowerCase()
        }
      },
      onCellClicked: onCellClicked,
    },
    {
      headerName: 'Sport',
      field: 'match.sport',
      suppressMovable: true,
      cellRenderer: SportRenderer,
      sortable: false,
      unSortIcon: true,
      minWidth: 170,
      valueGetter: (params) => {
        const { data } = params
        let sport = ''
        if (data?.betSlips && data?.betSlips[0] && data.betSlips[0]?.match) {
          sport = data.betSlips[0]?.match.sport
        } else {
          sport = data?.match?.sport
        }
        return sport
      },
      onCellClicked: onCellClicked,
    },
    {
      headerName: 'Result',
      field: 'status',
      suppressMovable: true,
      cellRenderer: ResultRenderer,
      sortable: false,
      unSortIcon: true,
      valueGetter: (params) => {
        const { data } = params
        let value = ''
        if (data.status === 'active') {
          value = 'Active'
        } else {
          if (data?.grade === 'win') {
            value = 'Win'
          } else {
            if (data?.grade === 'refund') {
              value = 'Push'
            } else {
              value = 'Lose'
            }
          }
        }
        return value.toLowerCase()
      },
      minWidth: 110,
    },
    {
      headerName: 'Match',
      field: 'match.home_team',
      suppressMovable: true,
      cellRenderer: MatchRenderer,
      sortable: false,
      unSortIcon: true,
      valueGetter: (params) => {
        const { data } = params
        let match = ''
        if (data?.betSlips && data?.betSlips[0] && data.betSlips[0]?.match) {
          match = data.betSlips[0]?.match.home_team
        } else {
          match = data?.match?.home_team
        }
        return match
      },
      onCellClicked: onCellClicked,
      minWidth: 150,
    },
    {
      headerName: 'Bet',
      field: 'betPrice',
      suppressMovable: true,
      cellRenderer: (props) => <BetRenderer usdToBsv={usdToBsv} {...props} />,
      valueGetter: (params) => {
        const { data } = params
        let bet = ''
        if (data.betSlips) {
          bet = data.price
        } else {
          bet = data.betPrice
        }
        return bet
      },
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
    },
    {
      headerName: 'Date/Time',
      field: 'placedAt',
      suppressMovable: true,
      cellRenderer: DateRenderer,

      valueGetter: (params) => {
        const { data } = params
        let placedAt = data.placedAt
        //return formatDate(placedAt, 'MM/DD/YYYY')
        return new Date(placedAt).getTime()
      },
      onCellClicked: onCellClicked,
      sortable: false,
      // sortingOrder: ['asc', 'desc'],
      unSortIcon: true,
      minWidth: 150,
    },
    {
      headerName: 'Win',
      field: 'betPrice',
      suppressMovable: true,
      cellRenderer: (props) => <WinRenderer usdToBsv={usdToBsv} {...props} />,
      valueGetter: (params) => {
        const { data } = params
        let win = usdToBsv(
          getSinglePossibleWin({
            betPrice: data?.betPrice,
            price: data?.price,
          }),
        )
        return win
      },
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
    },
    {
      headerName: 'Coef',
      field: 'price',
      suppressMovable: true,
      cellRenderer: CoefRenderer,
      comparator: numberSort,
      valueGetter: (params) => {
        const { data } = params
        return calculateCoefficient(data.price)
      },
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 130,
    },
    {
      headerName: '',
      field: 'notes',
      suppressMovable: true,
      cellRenderer: DropDownRenderer,
      minWidth: 120,
      onCellClicked: (params) => {
        const { data, rowIndex } = params
        if (!data.betSlips) {
          onIdPress(params?.data)
          return
        }
        const api = gridRef.current.api
        if (data.collapsed === true) {
          data.collapsed = false
          const arr = []
          data?.betSlips?.forEach((bet, i) => {
            const obj = { ...bet }
            obj['rowHeight'] = 60
            obj['betPrice'] = ''
            obj['price'] = ''
            obj['betID'] = ''
            obj['status'] = ''
            obj['placedAt'] = `${data.placedAt}`
            obj['id'] = `bet-${data.betID}-${i}`
            arr.push(obj)
          })

          api.applyTransaction({
            add: arr,
            addIndex: rowIndex + 1,
          })
          api.applyTransaction({
            update: [data],
          })
        } else {
          const arr = []
          const nodes = api.getRenderedNodes()

          data.betSlips.forEach((bet, i) => {
            const fill = nodes.filter((node) => {
              const nodeId = node.data.id
              const betId = `bet-${data.betID}-${i}`
              if (nodeId === betId) {
                return bet
              }
            })
            arr.push(fill[0]?.data)
          })

          data.collapsed = true

          api.applyTransaction({
            update: [data],
          })
          api.applyTransaction({
            remove: arr,
          })
        }
        setDetected(!detected)
        setCollapse(true)
      },
    },
  ]
  const paymentStatusColumn = {
    headerName: 'Payment Status',
    field: 'status',
    suppressMovable: true,
    cellRenderer: PaymentStatusRenderer,
    sortable: false,
    unSortIcon: true,
    valueGetter: (params) => {
      const { data } = params
      let value = ''
      if (data.status === 'active') {
        value = 'Active'
      } else if (data?.status === 'completed') {
        value = 'Completed'
      } else {
        value = 'NA'
      }
      return value.toLowerCase()
    },
    minWidth: 110,
  }
  const adminColumns =
    isAdmin && router.pathname.includes('admin/bets')
      ? [paymentStatusColumn]
      : []
  const columnDefinitions = [...columnDefs, ...adminColumns]
  const columnMobile = [
    {
      headerName: 'Event',
      field: 'match',
      suppressMovable: true,
      cellRenderer: SportMobileRenderer,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
      valueGetter: (params) => {
        const { data } = params
        let match = ''
        let filterText = ''
        if (data?.betSlips && data?.betSlips[0] && data.betSlips[0]?.match) {
          match = data.betSlips[0]?.match.home_team
          filterText = getTeamAbbreviation(
            data.betSlips[0]?.match.league,
            match,
          )
        } else {
          match = data?.match?.home_team
          filterText = getTeamAbbreviation(data?.match?.league, match)
        }
        return `${match} ${filterText}`
      },
      onCellClicked: onCellClicked,
    },
    {
      headerName: 'Result',
      field: 'status',
      suppressMovable: true,
      cellRenderer: ResultRenderer,
      sortable: false,
      unSortIcon: true,
      valueGetter: (params) => {
        const { data } = params
        let value = ''
        if (data.status === 'active') {
          value = 'Active'
        } else {
          if (data?.grade === 'win') {
            value = 'Win'
          } else {
            if (data?.grade === 'refund') {
              value = 'Push'
            } else {
              value = 'Lose'
            }
          }
        }
        return value.toLowerCase()
      },
      onCellClicked: onCellClicked,

      minWidth: 150,
    },
    {
      headerName: 'Bet Amount',
      field: 'betPrice',
      suppressMovable: true,
      cellRenderer: (props) => <BetRenderer usdToBsv={usdToBsv} {...props} />,
      valueGetter: (params) => {
        const { data } = params
        let bet = ''
        if (data.betSlips) {
          bet = data.price
        } else {
          bet = data.betPrice
        }
        return bet
      },

      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      sortable: false,
    }
  }, [])

  const getRowHeight = useCallback((params) => {
    return params.data.rowHeight
  }, [])

  return (
    <div
      className={`${
        isAdminBets || isMyBets ? 'h-[500px]' : 'h-[720px]'
      }  pb-[0.5] mx-auto my-8  ${
        isHome === true ? 'w-[100%]' : 'w-[95%] 2xl:w-[97%]'
      }`}
      // id="grid-wrapper"
    >
      <div style={gridStyle} className="ag-theme-alpine-dark scroller mb-1">
        <AgGridReact
          ref={gridRef}
          rowData={allBets}
          columnDefs={sm ? columnMobile : columnDefinitions}
          defaultColDef={defaultColDef}
          getRowHeight={getRowHeight}
          groupDefaultExpanded={1}
          animateRows={true}
          sortModel={sortModel}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444;">No Results Found</span>'
          }
          suppressScrollOnNewData
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit()
          }}
        />
      </div>
    </div>
  )
}

export default BetsTable
