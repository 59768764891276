import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { calculateCoefficient, statusColors } from './data'
import { StatusChip } from './StatusChip'
import NextImage from '@/utils/Image'
import { toPlainString } from '@/utils/toPlainString'

export let gridRef = ''

const BetsTable = ({
  allBets,
  bsvToUsd,
  currencyRate = null,
  isHome = false,
  type = null,
  customColor = null,
}) => {
  gridRef = useRef(null)
  const gridStyle = useMemo(
    () => ({
      height: '100%',
      width: '100%',
      boxSizing: 'border-box',
    }),
    [],
  )
  const [rows, setRows] = useState([])

  const PlinkoSportRenderer = (props) => {
    const data = props.data
    let sport = ''
    if (data?.betSlips && data?.betSlips[0] && data.betSlips[0]?.match) {
      return <></>
      // sport = data.betSlips[0]?.match.sport
    } else {
      sport = data.date
    }

    return (
      <div className="text-sm font-medium capitalize flex gap-x-5 w-[100%] items-center justify-center mx-auto">
        <div className="p-2 ">
          <span className="flex items-center justify-center">{sport}</span>
        </div>
      </div>
    )
  }

  const PlinkoResultRenderer = (props) => {
    const data = props.data

    if (data.floorColor === '') {
      return <></>
    }
    return (
      <StatusChip
        status={data?.floorColor}
        className={`${statusColors[data?.floorColor]} capitalize`}
      />
    )
  }

  const BetRenderer = (props) => {
    const data = props.data
    let bet = ''
    if (data.betSlips) {
      bet = data.price
    } else {
      bet = data.amount
    }

    return (
      <div className="text-sm font-medium space-y-2 cursor-pointer">
        <div className="flex gap-x-2 items-center relative">
          <NextImage
            width={16}
            height={16}
            src="/svgs/BSV.svg"
            className="w-4 h-4"
            alt="bsv logo"
          />{' '}
          {bet}
        </div>
        <div>
          ${' '}
          {currencyRate
            ? toPlainString((bet * 100000000 * currencyRate)?.toFixed(5))
            : Number(bsvToUsd(bet) || 0.0)?.toFixed(5)}
        </div>
      </div>
    )
  }

  const PayoutRenderer = (props) => {
    const data = props.data
    let bet = ''
    let earn = 0
    if (data.betSlips) {
      bet = data.price
    } else {
      bet = data.amount
      earn = data.res * data.amount
    }
    return (
      <div
        className={`text-sm font-medium space-y-2 cursor-pointer ${
          +earn > 0 ? 'text-yellow-600' : 'text-gray-600'
        }`}
      >
        <div className={`flex gap-x-2 items-center relative`}>
          <NextImage
            width={16}
            height={16}
            src="/svgs/BSV.svg"
            className="w-4 h-4"
            alt="bsv"
          />{' '}
          {earn.toFixed(5)}
        </div>
        <div>
          {+earn > 0 ? '+' : earn === 0 ? '' : '-'}${' '}
          {currencyRate
            ? toPlainString((earn * 100000000 * currencyRate)?.toFixed(5))
            : Number(bsvToUsd(bet) || 0.0)?.toFixed(5)}
        </div>
      </div>
    )
  }

  const CoefRenderer = (props) => {
    const data = props.data
    // if (data.price === '') {
    //   return <></>
    // }

    return (
      <StatusChip
        status={calculateCoefficient(data)}
        className={`${statusColors['grey']} capitalize`}
      />
    )
  }

  const PlinkoOdds = (props) => {
    const { data, rowIndex, colDef } = props
    const reversedData = [...data?.odds].reverse()
    const selectedRow = rowIndex
    const colIndex =
      colDef.colIndex === 0 ? colDef.colIndex : colDef.colIndex - 1
    const betCount = Array.isArray(reversedData) && reversedData[colIndex]?.bets
    const won = Array.isArray(reversedData) && reversedData[colIndex]?.won

    // const data = props.data
    // let sport = ''
    // if (data?.betSlips && data?.betSlips[0] && data.betSlips[0]?.match) {
    //   return <></>
    //   // sport = data.betSlips[0]?.match.sport
    // } else {
    //   sport = data.date
    // }
    // const currentSport = getSportByName(sport)

    const rowName = (key) => {
      switch (key) {
        case 0:
          return 'Bet Count'
        case 1:
          return 'Bet Rate'
        case 2:
          return 'Won'
        case 3:
          return 'Won Rate'
      }
    }

    const rowValue = (key) => {
      switch (key) {
        case 0:
          return betCount
        case 1:
          return betCount !== 0
            ? ((betCount / data.totalBets) * 100).toFixed(2)
            : betCount
        case 2:
          return won !== 0 ? won?.toFixed(5) : won
        case 3:
          const rateCalculate =
            Number(((won / data.totalWon) * 100).toFixed(2)) !== 0
              ? ((won / data.totalWon) * 100).toFixed(2)
              : ((won / data.totalWon) * 100).toFixed(3)
          return won !== 0 ? rateCalculate : won
      }
    }

    const keyColorPicker = (key) => {
      switch (key) {
        case 'blue':
          return 'text-blue-300'
        case 'green':
          return 'text-green-300'
        case 'yellow':
          return 'text-yellow-300'
        case 'red':
          return 'text-red-300'
        default:
          return ''
      }
    }
    const valueColorPicker = (key) => {
      switch (key) {
        case 'blue':
          return 'text-blue-500'
        case 'green':
          return 'text-green-500'
        case 'yellow':
          return 'text-yellow-500'
        case 'red':
          return 'text-red-500'
        default:
          return ''
      }
    }
    return (
      <div className="text-sm font-medium capitalize flex justify-center  w-[100%]">
        <div className="p-2 flex flex-col">
          {props.colDef.colIndex === 0 ? (
            <>
              <span className={keyColorPicker(customColor)}>
                {colIndex === 0 ? rowName(selectedRow) : rowValue(selectedRow)}
              </span>
            </>
          ) : (
            <span
              className={`${
                Number(rowValue(selectedRow)) !== 0
                  ? valueColorPicker(customColor)
                  : 'text-white'
              }`}
            >
              {rowValue(selectedRow)}
            </span>
          )}
        </div>
      </div>
    )
  }

  const casionColumnDefs = [
    {
      headerName: 'Bet',
      field: 'betPrice',
      suppressMovable: true,
      cellRenderer: BetRenderer,
      sortable: true,
      unSortIcon: true,
      minWidth: 100,
    },
    {
      headerName: 'Payouts',
      field: 'payouts',
      suppressMovable: true,
      cellRenderer: PayoutRenderer,
      sortable: true,
      unSortIcon: true,
      minWidth: 100,
    },
    {
      headerName: 'Time(UTC)',
      field: 'Sport',
      suppressMovable: true,
      cellRenderer: PlinkoSportRenderer,
      sortable: true,
      unSortIcon: true,
      minWidth: 100,
    },

    {
      headerName: 'Multiplier',
      field: 'multiplier',
      suppressMovable: true,
      cellRenderer: CoefRenderer,
      sortable: true,
      unSortIcon: true,
      minWidth: 100,
    },
    {
      headerName: 'My Pick',
      field: 'MyPick',
      suppressMovable: true,
      cellRenderer: PlinkoResultRenderer,
      sortable: true,
      unSortIcon: true,
      minWidth: 100,
    },
  ]

  const statsColumnDefs = (floor) => {
    const headerNames = {
      blue: [
        'Blue',
        'x1.1',
        'x1.1',
        'x1.1',
        'x0.2',
        'x1.1',
        'x1.2',
        'x1.3',
        'x1.4',
        'x3',
      ],
      green: [
        'Green',
        'x0.4',
        'x1',
        'x1.1',
        'x1.2',
        'x1.4',
        'x2',
        'x3',
        'x5',
        'x22',
      ],
      yellow: [
        'Yellow',
        'x0.3',
        'x0.5',
        'x1',
        'x1.4',
        'x3',
        'x5',
        'x12',
        'x38',
        'x111',
      ],
      red: [
        'Red',
        'x0.2',
        'x0.2',
        'x0.2',
        'x2',
        'x4',
        'x9',
        'x24',
        'x130',
        'x999',
      ],
    }

    return headerNames[floor].map((name, idx) => ({
      headerName: name,
      field: 'odd',
      suppressMovable: true,
      cellRenderer: PlinkoOdds,
      width: 120,
      colIndex: idx,
    }))
  }

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
    }
  }, [])

  useEffect(() => {
    if (type === 'plinkoStats') {
      setRows([allBets, allBets, allBets, allBets])
    } else {
      const data = allBets?.map((all) => {
        return { ...all }
      })
      setRows(data)
    }
  }, [allBets])

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setRowData(rows)
    }
  }, [rows])

  const onCellDoubleClicked = useCallback((params) => {
    if (params.colDef.showRowGroup) {
      params.node.setExpanded(!params.node.expanded)
    }
  }, [])

  const onCellKeyDown = useCallback(
    (params) => {
      if (!('colDef' in params)) {
        return
      }
      if (!(params.event instanceof KeyboardEvent)) {
        return
      }
      if (params.event.code !== 'Enter') {
        return
      }
      if (params.colDef.showRowGroup) {
        params.node.setExpanded(!params.node.expanded)
      }
    },
    [allBets],
  )

  return (
    <div
      className={`${
        customColor ? 'h-[310px]' : 'h-[500px]'
      } pb-[0.5] mx-auto my-2 ${
        isHome === true ? 'w-[100%]' : 'w-[95%] md:w-[97%] lg:max-w-7xl'
      } bg-black/70 rounded-md`}
      id="grid-wrapper"
    >
      <div
        style={gridStyle}
        className="py-2 ag-theme-alpine-dark scroller pb-1"
      >
        <AgGridReact
          ref={gridRef}
          rowData={rows}
          columnDefs={
            customColor ? statsColumnDefs(customColor) : casionColumnDefs
          }
          defaultColDef={defaultColDef}
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit()
            if (gridRef.current) {
              gridRef.current.api.setRowData(rows)
            }
          }}
          getRowHeight={() => 65} // Set the default row height
          groupDefaultExpanded={-1} // Set the default collapsed state to true
          onCellDoubleClicked={onCellDoubleClicked}
          onCellKeyDown={onCellKeyDown}
          animateRows={true}
          id="myGrid"
          className="my-custom-grid"
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; ">No Results Found</span>'
          }
        />
      </div>
    </div>
  )
}

export default BetsTable
